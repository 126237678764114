import { toFieldPath } from "@stackbit/annotations";
import React from "react";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import { SharedSanitySectionProps } from "../../Layouts/types";
import { SharedTitleAreaProps, TitleArea } from "../components/TitleArea";
import { NavigationCard } from "./NavigationCard";
import { NavigationCardProps } from "./types";
interface NavigationCardsProps extends SharedSanitySectionProps, SharedTitleAreaProps {
  items?: NavigationCardProps[];
}
const SectionNavigationCards: React.FC<NavigationCardsProps> = ({
  annotationPrefix,
  bgClassName,
  sectionVariant = "normal",
  section_id,
  items = [],
  ...titleProps
}) => {
  return <Section id={section_id} variant={sectionVariant} bgClassName={bgClassName} {...toFieldPath(annotationPrefix)} data-sentry-element="Section" data-sentry-component="SectionNavigationCards" data-sentry-source-file="SectionNavigationCards.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="SectionNavigationCards.tsx">
        <TitleArea {...titleProps} data-sentry-element="TitleArea" data-sentry-source-file="SectionNavigationCards.tsx" />
        <div {...toFieldPath(".items")} className="mt-6 lg:mt-10 grid grid-flow-row lg:grid-flow-col gap-4 items-stretch">
          {items.map((item, index) => <NavigationCard key={index} {...toFieldPath(`.[${index}]`)} {...item} />)}
        </div>
      </Container>
    </Section>;
};
export default SectionNavigationCards;