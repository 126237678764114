import { toFieldPath } from "@stackbit/annotations";
import React from "react";
import Link from "next/link";
import { NavigationCardProps } from "./types";
import { TextWithArrow } from "../components/TextWithArrow";
export const NavigationCard: React.FC<NavigationCardProps> = ({
  title,
  description,
  href,
  linkText = "Learn more",
  ...props
}) => {
  return <Link href={href} className="md:p-8 p-5 border border-current rounded-lg flex flex-col lg:min-h-[230px] hover:bg-current-10 " {...props} data-sentry-element="Link" data-sentry-component="NavigationCard" data-sentry-source-file="NavigationCard.tsx">
      <div className="text-h2 lg:text-h1" {...toFieldPath(".title")}>
        {title}
      </div>
      <div className="mt-2 text-body-standard" {...toFieldPath(".description")}>
        {description}
      </div>
      <div className="flex-1 min-h-[32px]"></div>
      <TextWithArrow text={linkText} fieldPath=".linkText" className="text-body-standard font-medium" data-sentry-element="TextWithArrow" data-sentry-source-file="NavigationCard.tsx" />
    </Link>;
};